<template>
  <div></div>
</template>
<script>

export default {
  _config: {route: {path: "list", meta: {title: "列表", keepAlive: true}}},
  mounted() {
    setTimeout(()=>{
      window.open(process.env.VUE_APP_URL_JUMP + '?token=' + sessionStorage.getItem('TOKEN'));
    },100)
  }
}
</script>

<style lang="scss" scoped>
</style>
